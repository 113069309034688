<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    width="500px"
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addPrice)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="font-weight-bold blue-grey--text font-size-lg ft ml-1"
              >Add Price</span
            >
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    outlined
                    :items="products"
                    item-text="name"
                    item-value="id"
                    :error-messages="errors[0]"
                    class="ft font-weight-medium"
                    v-model="price.productId"
                    label="Product"
                    hint="Choose from product list"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    v-model="price.amount"
                    label="Amount"
                    :error-messages="errors[0]"
                    hint="Product price"
                  />
                </ValidationProvider>
              </v-row>
              <v-row class="mt-n5 ml-n4">
                <v-checkbox
                  v-model="price.isCurrent"
                  class="ft font-weight-medium "
                  :label="`Set  as the current price`"
                ></v-checkbox>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2 ft text-capitalize" text @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              type="submit"
              class="ft white--text text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "NewProductDialog",
  props: {
    state: { type: Boolean, default: false },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    price: {
      amount: 0,
      productId: null,
      isCurrent: false
    }
  }),
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState",
      resetFormValues: "prices/getResetFormValues"
    })
  },
  watch: {
    resetFormState(value) {
      if (value) {
        this.price = { ...this.resetFormValues };
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 1000);
    }
  },
  methods: {
    addPrice() {
      this.$emit("price", {
        loading: { idx: "price", status: false },
        body: this.price
      });
    },
    close() {
      this.$emit("actions", "price");
    }
  }
};
</script>
